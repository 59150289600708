body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}
.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}
.invoice-box table tr td:nth-child(2) {
  text-align: right;
}
.invoice-box table tr.top table td {
  padding-bottom: 20px;
}
.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}
.invoice-box table tr.information table td {
  padding-bottom: 40px;
}
.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}
.invoice-box table tr.details td {
  padding-bottom: 20px;
}
.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}
.invoice-box table tr.item.last td {
  border-bottom: none;
}
.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }
  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}
.invoice-box.rtl table {
  text-align: right;
}
.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.notification-box {
  top: 55px;
  position: absolute;
  background: white;
  width: 404px;
  height: 465px;
  left: -146px;
  border-radius: 5px;
  z-index: 9;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 0.5px solid rgb(205, 201, 201);
  padding: 12px;
  border-radius: 0 10px 0 0;
}
.notification-item-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* .notification-item p {
  margin: 0;
  font-weight: 500;
  width: 100%;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.msg {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  margin: 0;
  padding: 0;
  font-weight: 500;
 
}
.msg p{
  margin: 0;
  padding: 0;
  font-weight: 500;
 
}
.noti_heading {
  padding: 0 10px;
  font-weight: 600;
  background: #e2e1e1;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.empty_noti{
  height: 465px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.upload-column{
  height: 390px !important;
}